.TwoWayText {
    user-select: none;

    h4, h5, h6 {
        font-weight: 700;
    }
    h4 {
        font-size: 1.5em;
    }
    h5 {
        font-size: 1.25em;
    }
    h6 {
        font-size: 1.125em;
    }

    .TwoWayText__Body {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

        .MuiDivider-vertical {
            border: solid 1px #979797;
            opacity: 0.35;
            display: inline;
        }
        
        .MuiDivider-middle {
            margin: 8px 16px;
        }
    }

    .TwoWayText__SystemMessages, .TwoWayText__CustomMessages {
        overflow-y: auto;
        
        .TwoWayText__SystemMessages--content, .TwoWayText__CustomMessages--content {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1em;
            padding: 12px 0 8px 16px;
    
            svg {
                cursor: pointer;
            }
        }
    }

    .TwoWayText__SystemMessages {
        max-height: 200px;
    }

    .TwoWayText__CustomMessages {
        max-height: 400px;
    }

    .MuiButton-endIcon {
        width: 22.5px;
        height: 22.5px;
    }

 

}