.reporting-data {
    padding: 24px 16px 8px 16px;
    h5 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4051b5;
    }

    h6 {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    .MuiTableCell-root {
        padding: 4px;
    }

    .MuiTable-root tr td:first-child div {
        justify-content: center;
    }

    .MuiTableCell-head {
        line-height: 1.25rem;
    }

    thead tr th:first-child[style] {
        width: 80px !important;
    }
}


