.availability-errors{
    padding: 10px;
    overflow: hidden !important;

    h4 {
        color: hsl(34, 94%, 58%);
        font-weight: 600;
        margin-left: 20px;
        font-size: 16px;
    }

    .errors-list {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        h3 {
            margin: 0;
            margin-bottom: 5px;
            color: #4051b5;
        }
        p {
            font-size: 14px;
            font-weight: 600;
            margin: 10px;
        }
        .errors-list--errors {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 0.5em;

            button {
                padding: 5px;
                margin: 5px;
                width: 200px;
                height: 40px;
                cursor: pointer;
                background-color: #3f51b5;
                color: #fff;
                border: none;
                border-radius: 4px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                transition-duration: 0.4s;
                box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12);
            }
            button:hover {
                background-color: hsl(34, 94%, 58%);
                color: black;
                font-weight: 600;
            }
        }
    }

    .errors-button {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 1em;
    }
}