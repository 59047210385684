

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.2);
    z-index: 9000;
}

.modal {
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    max-height: 90vh;
    position: fixed;
    background: #FFF;
    padding: 1.75rem;
    z-index: 9001;
    border-radius: 1.5rem;
    width: 725px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    select {
        font: inherit;
        padding: 10.5px 32px 10.5px 14px;
    }

    .modal--label {
        transform: translate(0, 1.5px) scale(0.75);
        margin-bottom: 0;
    }

    .modal__close {
        position: absolute;
        top: 5%;
        left: 95%;
        transform: translate(-50%, -50%);
        z-index: 2;
        background: #DFDEDF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 0.5;
        cursor: pointer;
    }

    .modal__content {
        height: 100%;
        flex: 1;
    }

    .modal__content--title {
        display: flex;
        flex-direction: row;
        align-content: center;

        h1, h2 {
            margin: 0;
            font-size: 1.5em;    
            font-family: "Open Sans", sans-serif;
            user-select: none;
        }
        h1 {
            color: #3f51b5;
        }
        h2 {
            margin-left: 0.5rem;
        }
    }

    .modal__content--body {
        user-select: none;
        margin: 2em 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow-y: auto;

        h1, h2 {
            margin: 0;
            font-size: 1.5em;    
            font-family: "Open Sans", sans-serif;
            user-select: none;
        }
        h1 {
            color: #3f51b5;
        }

        p {
            margin: 0.5em 0 0 0.5em;
            font-weight: 600; 
        }


        .modal__content__twoway--charcount-emoji {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;

            span {
                font-size: .8rem;
                color: #ccc;
            }
        }
    }

    .modal__content--buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 1em;
    }
}

