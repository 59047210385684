@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/shapes';
@import '../../Styles/buttons';

.admin-menu {
  align-items: center;
  background-color: theme-color('primary');
  display: flex;
  height: 100%;
  padding: 0 1em;
  position: relative;
  z-index: 1;

  @media (min-width: $screen-lg) {
    padding: 0;
  }
}

.admin-menu__brand {
  align-items: center;
  display: flex;
  height: 100%;
  width: 4.5em;

  @media (min-width: $screen-lg) {
    border-right: 1px solid theme-color('background');
    border-left: 1px solid theme-color('background');
    padding: 0 2em;
    width: 15em;
  }
}

.admin-menu__logo {
  width: 100%;
}

.admin-menu__logo--mobile {
  display: none;

  @media (max-width: $screen-lg) {
    display: initial;
  }
}

.admin-menu__logo--desktop {
  display: none;

  @media (min-width: $screen-lg) {
    display: initial;
  }
}

.admin-menu__menu {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 1em;
  width: 100%;
  z-index: 5;
}

.admin-menu__locations {
  width: 12em;

  @media (min-width: $screen-lg) {
    width: 20em;
  }
}

.admin-menu__nav {
  align-items: center;
  display: flex;
}

.admin-menu__badges {
  display: flex;
  padding-right: 0.8em;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.admin-menu__badge {
  @extend .shape-circle;

  border: 1px solid theme-color('white');
  height: 14px;
  width: 14px;
}

.admin-menu__badge--success {
  background-color: theme-color('success');
}

.admin-menu__badge--error {
  background-color: theme-color('error');
}

.admin-menu__badge--overlap {
  margin-left: -3px;
}

.admin-menu__hamb-cont {
  display: none;

  @media (min-width: $screen-lg) {
    display: flex;
    padding: 0 1.5em;
  }
}

.admin-menu__hamb {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  @media (min-width: $screen-lg) {
    display: none !important;
  }
}

.admin-menu__hamb--menu {
  display: none !important;

  @media (min-width: $screen-lg) {
    display: initial !important;
  }
}

.admin-menu__hamb--expanded {
  .admin-menu__hamb-icon {
    &:before {
      transform: translateY(8px) rotate(135deg);
    }

    &:after {
      transform: translateY(-8px) rotate(-135deg);
    }

    > div {
      transform: scale(0);
    }
  }
}

.admin-menu__hamb-icon {
  width: 25px;

  &:before,
  &:after,
  > div {
    background-color: theme-color('white');
    border-radius: 3px;
    content: '';
    display: block;
    height: 3px;
    margin: 5px 0;
    transition: all .2s ease-in-out;
  }

  &:before {
    transform: translateY(0) rotate(0deg);
  }

  &:after {
    transform: translateY(0) rotate(0deg);
  }

  > div {
    transform: scale(1);
  }
}

.admin-menu__items {
  display: none;
  
  @media (min-width: $screen-lg) {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

.admin-menu__items--mobile {
  display: flex;
  flex-direction: column;
  height: 92%;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.admin-menu__item-cont {
  align-items: center;
  border-top: 1px solid theme-color('background', 'medium');
  color: theme-color('background', 'medium');
  display: flex;
  padding: 1em 0 1em 2em;

  &:last-child {
    border-bottom: 1px solid theme-color('background', 'medium');
  }
}

.admin-menu__item-cont--active {
  background-color: theme-color('primary2', 'light');
  color: theme-color('white');
}

.admin-menu__item {
  @extend .button--link;

  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  margin: 0 1em;
}

.admin-menu__item--mobile {
  color: inherit;
  font-weight: 500;
}

.admin-menu__add-appt {
  @extend .button;
  @extend .button--primary;

  margin: 0 1em;
  text-transform: uppercase;
}

.admin-menu__notifications {
  @media (min-width: $screen-lg) {
    background-color: theme-color('red');
    color: theme-color('white');
    display: flex;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 64px;
    
    p { 
      margin: 0;
      margin-left: 5px;
      margin-top: 2px;
    }
  }
}

.li-schedule-setup {
  padding-left: 56px;
}
