.triagereports {
    h5, h6 {
        display: inline;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    h5 {
        font-size: 20px;
        font-weight: 600;
    }

    h6 {
        font-size: 18px;
        font-weight: 500;
    }

    .MuiDivider-vertical {
        border: solid 1px #979797;
        opacity: 0.8;
        display: inline;
    }
    
    &--page__title {
        flex-grow: 1; 
        max-width: 1700px;
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
        margin-bottom: 0.8em;

        &--address__triage {
            padding-left: 170px;
        }

        &--address__shadowupload {
            padding-left: 240px;
        }

        &--address__shadowreport {
            padding-left: 230px;
        }
    }
}

