@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/forms';
@import '../../Styles/buttons';

.login {
  background-image: url('../../Assets/Images/Blue-Corporate-Background.jpg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-rows: 4em auto 3em;
  grid-template-areas:
    "header"
    "content"
    "footer";
  height: calc(var(--vh, 1vh) * 100);

  @media (min-width: $screen-lg) {
    background: none;
    grid-template-rows: 100%;
    grid-template-columns: 30% 70%;
    grid-template-areas:
    "header content";
    height: 100vh;
  }
}
