.filters {
    input[type="text"]:disabled {
        background-color: initial;
        color: #e0e0e0;
    }

    .flex-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 4px 0;

        p {
            margin: 0;
            font-size: 16px;
            padding-right: 4px;
        }

        span {
            padding: 0;
        }
    }
}