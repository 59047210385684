.ws-general-info {
    label {
        font-size: 14px;
    }

    .MuiIconButton-root:not(.use-def) {
        padding: 12px !important;        
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        margin: 4px;
        width: 32px;
        height: 32px;
    }

    .MuiGrid-item {
        padding: 12px 40px !important;

        .MuiInputBase-root {
            width: 100%;
        }
    }

    .title-row {
        border-bottom: 1px solid #e0e0e0;
        margin: 20px 0;

        h3 {
            margin: 0;
        }

        .MuiGrid-item {
            padding: 12px !important;
        }
    }

    input#MobileLogoUrl, input#DesktopLogoUrl, input#ProfilePic {
        cursor: not-allowed;
    }

    #use-default-cb {
        padding-left: 7px;
    }
}