.column-location {
    float: left;
    width: 50%;
  }
  
  .row-location:after {
    content: "";
    display: table;
    clear: both;
  }
  
  div.column-location div {
    padding-bottom: 5px;
  }

  #stateLabel{
    transform: translate(0, 1.5px) scale(0.75);
    margin-bottom: 0;
  }

  .MuiButton-endIcon.MuiButton-iconSizeSmall {
    margin-left: 4px;
  }

  #btnDeleteLocation:hover {
    background-color: #3f51b5;
    color: white;
  }

  #grid-buttons {
    margin: 5px 0 5px 0;
  }
  