@import '../../Styles/theme';


input[type="text"]:disabled {
    background: #ffffff;
    cursor: default;
  }

.coverKeyLabel{
    transform: translate(0, 1.5px) scale(0.75);
    margin-bottom: 0;
  }
