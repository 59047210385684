.coverlookup {

    .coverlookup-data {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

        .search-option {
            padding: 8px 16px;

            hr {
                margin-block-end: 0;
                margin-block-start: 0;
            }
 
            .search-fields {
                padding: 4px 8px;
                border: 1px solid #979797;
                border-radius: 5px;
                display: inherit;
            }
        }
    }
               
    .MuiDivider-vertical {
        border: solid 1px #979797;
        opacity: 0.35;
        display: inline;
    }

    .MuiDivider-middle {
        margin: 8px 16px;
    }

    h5 {
        font-weight: bold;
    }

    .MuiTable-root tr td:first-child div {
        justify-content: center;
    }

    .MuiTable-root thead tr th:first-child{
        width: 55px !important;
    }

    .validatedPhoneNumber {
        display: inline-flex;
        vertical-align: bottom;
        div {
            display:inline-flex;
            
        }
    } 
}

.shadowreport {

    .shadowreport-data {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

        input {
            font-size: 13px;
        }

        .MuiSelect-root {
            font-size: 13px;
        }
    }

    .divider-thick {
        height: 2px !important;
    }

    .MuiTable-root thead tr th:first-child{
        width: 100px !important;
    }
}

.location-provider {
    .location-provider-data {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

        .search-option {
            padding: 8px 16px;

            hr {
                margin-block-end: 0;
                margin-block-start: 0;
            }
 
            .search-fields {
                padding: 4px 8px;
                border: 1px solid #979797;
                border-radius: 5px;
                display: inherit;
            }
        }
    }

    .divider-thick {
        height: 2px !important;
    }
                   
    .MuiDivider-vertical {
        border: solid 1px #979797;
        opacity: 0.35;
        display: inline;
    }

    .MuiDivider-middle {
        margin: 8px 16px;
    }

    h5 {
        font-weight: bold;
    }

    h6 {
        color: #3f51b5;
    }

	.pageLabel {
		transform: translate(0, 1.5px) scale(0.75);
		margin-bottom: 0;
		font-size: 0.5rem !important;
	}

    .custom-select {
        width: 200px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        color: #333;
      
        option {
          background-color: #fff;
          color: #333;
        }
      }
}

