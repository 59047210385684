.rowItem {
  grid-row: 1;
}
.rowItemHeader {
  grid-row: 1;
  font-weight: bold;
  font-size: large;
}
.rowItemHeader2 {
  grid-row: 1;
  font-weight: bold;
}
.emailColumn {
  border-right: 0.5px solid grey;
  padding-right: 10px !important;
}
.smsColumn {
  border-right: 0.5px solid grey;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.voiceColumn {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
