.filters {
    margin-top: 20px;
    margin-bottom: 20px;
    
    #Cover, #Location, #Provider {
        .material-icons {
            vertical-align: middle;
            margin-right: 5px;
        }
    }
}

.static-notify {
    margin: 50px 0;
    text-align: center;
    color: blue;
}

.static-notify-norm {
    margin: 50px 0;
    text-align: center;
}

.ws-item {
    display: none;
}

.ws-item.showme {
    display: block;
}

.page-body-outline {
    padding: 20px;
}

.select-cover-message {
    border: 1px solid #e0e0e0;
    background-color: white;
    color: darkblue;
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}