.uicontext-dialog {
    z-index: 9002 !important;
    #responsive-dialog-title {
        background-color: royalblue;
        color: white;

        h2 {
            font-size: 16px;
        }

        .MuiSvgIcon-root {
            vertical-align: middle;
            margin-right: 10px;
        }

        .dialogCloseIcon {
            position: absolute;
            right: -4px;
            top: 4px;
            cursor: pointer;
        }
    }

    .MuiDialogContent-root {
        border: 1px solid #e0e0e0;
    
        .MuiDialogContentText-root {            
            margin-bottom: 0;
            min-width: 250px;
            min-height: 40px;
            color: black;
        }        
    }

    .MuiDialogActions-root {
        background-color: #fafafa;
    }
}

.uicontext-dialog.error-box {
    #responsive-dialog-title {
        background-color: red;
        color: white;
    }
}