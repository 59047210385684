.onboarding-header {
  h5 {
      display: inline;
      font-size: 23px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
  }

  h6 {
      display: inline;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
  }

  .MuiDivider-vertical {
      border: solid 1px #979797;
      display: inline;
      opacity: 0.8;
  }

  .MuiSelect-root {
    padding: 8px;
    border-radius: 4px;
    border: solid 1px #979797;
  }
}

#div-listview {  
  position: relative;

  .reload-btn {
    float: right;
    display: block;
    margin-top: 25px;
    margin-right: 40px;
  }
}

.onboarding-body {
  > .MuiPaper-root {
    position: initial !important;
  }

  h6 {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
  }

  h5 {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .MuiToolbar-root div:nth-child(2){
    flex: 0;
  }

  .form-input {
      padding-top: 4px;
      border-radius: 4px;
      border: solid 1px #979797;
      width: 300px;
  }

  th {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .MuiTable-root tr td:first-child div {
    justify-content: center;
  }

  .box-onboarding-body {
    padding: 5px 41.2px 112px 33px;
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

    .provider-edit-delete, .location-edit-delete{
      .MuiDivider-root {
        border: solid 1px #979797;
        margin: 16px 0 16px 0;
      }
    }
  }
}

.listHeader {
  color: #4051b5;
  font-size: 20px;
}

div.divListItem hr.MuiDivider-root {
  margin: 0 2% 0 2%;
}

.icon-flipped span.material-icons {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}
#grid-container{
  display: inline-block;
}

.grdIco {
  float: right;
  display: inline-flex;
  align-items: baseline;
}

.grdText {
  float: left;
  display: inline-flex;
  align-items: baseline;
}

.clsButton {
  float: right;
  padding-top: 7px;

}

.page-cont {
  > div {
    display: none;
  }

  > div.showme {
    display: block;
  }
}


// #header-view-items {
//   > div {
//     display: none;
//   }
// }

// #header-view-items.booking .vw-booking,
// #header-view-items.back .vw-back {
//   display: block;
// }