.v5datautility {
	background-color: whitesmoke;
	border: 1px solid #e0e0e0;
	padding: 10px;
}

.v5migration-table {
	border-spacing: 0;
	border-collapse: collapse;
	width: 100%;
	margin: 10px 0;
	background-color: whitesmoke;

	thead {
		background-color: #e0e0e0;
	}

	th,
	td {
		border: 1px solid #000;
		padding: 4px 20px;
		vertical-align: middle;
		max-height: 50px;
		white-space: nowrap;
		text-align: left !important;

		ul,
		ol {
			max-height: 60px;
		}

		ul {
			margin: 0;
			padding-left: 20px;
		}

		input[type="checkbox"] {
			cursor: pointer;
			width: 12px;
			height: 12px;
		}
	}

	th:nth-child(1),
	td:nth-child(1) {
		width: 50px;
		text-align: center;
		vertical-align: middle;

		input {
			margin: 0;
		}
	}

	td:nth-child(2) {
		overflow: auto;
	}
}

.v5Migration-legacydata table {
	@extend .v5migration-table;
	width: 100%;

	td {
		font-size: 12px !important;
		padding: 2px 10px;
		text-align: left !important;
		border: 1px solid #e0e0e0;
		overflow: auto;

		ul {
			list-style: none;
			padding: 0;
		}
	}
}

.v5migration-widget {
	margin-top: 25px;
	border: 1px solid dimgray;
	position: relative;
	transition: height 2s;

	.widget-handle {
		position: absolute;
		top: -20px;
		right: -1px;
		padding: 2px;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		background-color: dimgray;
		color: white;
		font-size: 10px;
		border: 1px solid dimgray;
		cursor: pointer;
	}

	.widget-title {
		display: inline-block;
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 3px;
	}

	table {
		@extend .v5migration-table;
		width: 100%;
		background-color: lightcyan;
		margin: 0;

		td {
			font-size: 12px;
			padding: 2px;
			text-align: left !important;
			border: 1px solid #e0e0e0;
			overflow: auto;
		}

		tr:first-child {
			td:first-child {
				vertical-align: middle;
				width: 250px;
				text-align: center !important;
			}

			td:not(:first-child) {
				font-size: 12px;
				font-weight: bold;
			}
		}

		tr:not(:first-child) {
			td:not(:last-child) {
				width: 150px;
			}
		}

		tr:last-child {
			td {
				text-align: center !important;
				background-color: lightgray;

				a {
					text-decoration: none;
				}
			}
		}
	}
}

.v5migration-widget.hide {
	height: 0;
	table {
		display: none;
	}
}

.v5migration {
	border: 1px solid darkred;
	margin: 10px 0;
	padding: 0 10px 10px 10px;

	table {
		@extend .v5migration-table;
	}

	select {
		-webkit-appearance: auto;
		width: initial;
		padding: 2px;
		border-radius: initial;
		border: 1px solid;
		font-weight: initial;
	}

	a {
		color: rgb(63, 81, 181);
	}

	.v5Migration-legacydata {
		table {
			width: 100%;
			font-size: 11px;

			td {
				padding: 2px;
			}
		}
	}
}
