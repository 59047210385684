.column {
  float: left;
  width: 50%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

div.column div {
  padding-bottom: 5px;
}

#locationLabel, #roleLabel{
  transform: translate(0, 1.5px) scale(0.75);
  margin-bottom: 0;
}

#providerLocation {
  padding: 4px;
  font-size: 10px;
  color: white;
	background-color: #9b9b9b;
	border-radius: 20px;
  line-height: 1;
  max-width: 128px;
  height: 40px;
	text-transform: initial;
  margin: 0 5px 5px 5px;
}

#btnDeleteProvider:hover {
  background-color: #3f51b5;
  color: white;
}

#grid-buttons {
  margin: 5px 0 5px 0;
}

